import React, { useEffect, useState } from 'react';
import { Image } from 'react-image-and-background-image-fade'

function HomePage() {
    return (
        <>
            <div className="mobile-address-topper">
                <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                718-383-5345 MON-SUN 8A-11P</p>
            </div>
            <div id="home-image-wrapper" className="masonry">
                    <div className="home-grid-image home-grid-image-large masonry-brick" id="HI-1">
                        <Image className="home-images" src={require("../landing_images/5lv_editorial_012014-03-05-04-12-39.jpg")} 
                        alt="waitress in hallway"
                        width="100%"
                        height="100%"  
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-2">
                        <Image className="home-images" src={require("../landing_images/5LBURGER2.jpg")} 
                        alt="five leaves burger"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-3">
                        <Image className="home-images" src={require("../landing_images/Coaster_DapperFish2013-06-06-15-11-00.gif")} 
                        alt="dancing dapper fish"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-4">
                        <Image className="home-images" src={require("../landing_images/5lv_editorial_072013-06-06-15-05-49.jpg")} 
                        alt="bartender serving a martini"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-5" >
                        <Image className="home-images" src={require("../landing_images/5lv_editorial_042013-06-06-15-05-48.jpg")} 
                        alt="black door and dining chair"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-6">
                        <Image className="home-images" src={require("../landing_images/5lv_food_062013-06-06-15-05-50.jpg")} 
                        alt="oysters and charcuterie with a glass of wine"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
            
                    <div className="home-grid-image masonry-brick" id="HI-7">
                        <Image className="home-images" src={require("../landing_images/Coaster_Drunkleton2013-06-06-15-11-00.gif")}
                        alt="skeleton drinking a beer" 
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-8">
                        <Image className="home-images" src={require("../landing_images/sam.jpg")} 
                        alt="bartender making drinks at the bar"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-9">
                        <Image className="home-images" src={require("../landing_images/Coaster_Oysters2013-06-06-15-11-00.gif")} 
                        alt="oysters blinking"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-10">
                        <Image className="home-images" src={require("../landing_images/5lv_editorial_062013-06-06-15-05-49.jpg")} 
                        alt="barista laughing with waiter"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-11">
                        <Image className="home-images" src={require("../landing_images/5lv_editorial_082013-06-06-15-05-49.jpg")} 
                        alt="patrons having dinner al fresco"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-12">
                        <Image className="home-images" src={require("../landing_images/SHIP_O_ICEedit.jpg")} 
                        alt="martini and flowers"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-13">
                        <Image className="home-images" src={require("../landing_images/Coaster_Kale2013-06-06-15-11-00.gif")} 
                        alt="angry kale"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-14">
                        <Image className="home-images" src={require("../landing_images/STEAK W_BEANS CLOSEUP.jpg")} 
                        alt="steak with beans and asparagus"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-15">
                        <Image className="home-images" src={require("../landing_images/BREAKKIE.jpg")} 
                        alt="big breakkie"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
                    <div className="home-grid-image masonry-brick" id="HI-16">
                        <Image className="home-images" src={require("../landing_images/5lv_food_032013-06-06-15-05-49.jpg")} 
                        alt="hot cup of joe"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-17">
                        <Image className="home-images" src={require("../landing_images/5lv_food_022013-06-06-15-05-49.jpg")} 
                        alt="rosewater pavlova"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-18">
                        <Image className="home-images" src={require("../landing_images/COCKTAIL POURedit.jpg")} 
                        alt="cocktail with a lemon twist"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-19">
                        <Image className="home-images" src={require("../landing_images/5lv_food_082013-06-06-15-05-50.jpg")} 
                        alt="kale salad"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-20">
                        <Image className="home-images" src={require("../landing_images/MOROCCAN2edit.jpg")} 
                        alt="moroccan scramble"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-21" >
                        <Image className="home-images" src={require("../landing_images/5lv_editorial_102013-06-06-15-05-49.jpg")} 
                        alt="black and white exterior of the restaurant"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-22">
                        <Image className="home-images" src={require("../landing_images/Coaster_DevilOnHorseback2013-06-06-15-11-00.gif")} 
                        alt="devil riding on horseback"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-23">
                        <Image className="home-images" src={require("../landing_images/5lv_food_072013-06-06-15-05-50.jpg")} 
                        alt="ricotta with a side of bread"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-24">
                        <Image className="home-images" src={require("../landing_images/Coaster_Octopus2013-06-06-15-11-00.gif")} 
                        alt="octopus ready to cook up some foodz"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>

                    <div className="home-grid-image masonry-brick" id="HI-25">
                        <Image className="home-images" src={require("../landing_images/CURED SALMONedit.jpg")} 
                        alt="cured salmon with hashbrowns"
                        width="100%"
                        height="100%" 
                        lazyLoad
                        />
                    </div>
                
            </div>
        </>
    )
}

export default HomePage;