import React, { useState, useRef, useEffect } from 'react';

function MenusDropdown() {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <button 
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)} 
            onKeyDown={() => setShowMenu(true)}
            className="menus-dd-button"
            aria-expanded={showMenu}
            >
                Menus
                {showMenu && (
                    <ul className="dropdown">
                        <a href="brunch" alt="brunch menus page">Brunch</a>
                        <a href="inbetweener" alt="inbetweener menus page">InBetweener</a>
                        <a href="dinner" alt="dinner menus page">Dinner</a>
                        <a href="cocktail" alt="cocktail list page">Cocktails</a>
                        <a href="wine" alt="wine list page">Wine</a>
                    </ul>
                )}
            </button>

        </>
    )
}

export default MenusDropdown;
