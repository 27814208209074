function Inbetweener() {
    return (
        <div id="inbetweener">
            <div className="mobile-address-topper">
                <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                718-383-5345 MON-SUN 8A-11P</p>
            </div>
            <h1 className ="FM-h1">Inbetweener</h1>
            <div className="row1">
                <img src={require('../../menu_images/INBETWEENER_1.png')} alt='seasonal inbetweener menu pg 1'></img>
                <img src={require('../../menu_images/INBETWEENER_2.png')} alt='seasonal inbetweener menu pg 2'></img>
            </div>
            <div className="row2">
                <img src={require('../../menu_images/INBETWEENER_3.png')} alt='seasonal inbetweener menu pg 3'></img>
                <img src={require('../../menu_images/INBETWEENER_4.png')} alt='seasonal inbetweener menu pg 4'></img>
            </div>
            <div className="screen-reader">
                <h3>Starters</h3>
                    <p>Daily Market Oysters. Half Dozen: $18. Dozen: $36</p>
                    {/* <p>Watermelon Gazpacho: $13</p> */}
                    <p>Deviled Eggs: $8</p>
                    <p>Fried Olives: $10</p>
                    <p>Green Pea Falafel: $18</p>
                    <p>Cheese Bread: $10</p>
                    <p>Devils On Horseback: $12</p>
                    <p>Housemade Ricotta: $18</p>
                    <p>French Onion Soup: $15</p>
                    <p>Steamed Mussels: $28</p>
                    <p>Artisinal Cheese Board: $26</p>
                    <p>Fries: $7</p>
                    <p>Truffle Fries: $9</p>
                <h3>Sandwiches</h3>
                    <p>Spicy Chicken: $20 (choice of grilled or crispy)</p>
                    <p>B.L.A.T.: $19</p>
                    <p>Roasted Veggies: $19</p>
                    <p>French Dip: $20</p>
                    <p>Veggie Burger: $21</p>
                    <p>Five Leaves Burger: $24</p>
                    <p>Burger: $21</p>
                    <p>Burger Additions : $2 each : bacon, chedder, gruyerre, blue, truffle fries</p>
                <h4>Salad</h4>
                    <p>Market Greens: $12</p>
                    <p>Black Kale: $16</p>
                    <p>Little Gem Wedge: $18</p>
                    <p>Add... Grilled Halloumi ($9), Marinated Chicken ($10), Grilled Shrimp ($12), Seared Salmon ($15), Grilled Steak ($15)</p>
                <h3>Baked In-House</h3>
                    <p>Classic Aussie Roll: $10</p>
                    <p>Spinach & Feta Roll: $10</p>
                    <p>Spicy Harissa Chicken Pie: $10</p>
                    <p>side of spiced ketchup +$1</p>
                <h3>Dessert</h3>
                    <p>Sticky Date Pudding: $12</p>
                    <p>Rosewater Pavlova: $12</p>
                    <p>Wine Poached Apple Strudel: $12</p>
                    <p>Affogato: $8</p>
                <h3>Coffee & Tea</h3>
                    <p>Espresso: $5</p>
                    <p>Americano: $5</p>
                    <p>Latte: $6</p>
                    <p>Flat White: $6</p>
                    <p>Cappuccino: $6</p>
                    <p>Cold Brew: $5</p>
                    <p>Mocha: $6</p>
                    <p>Turmeric Latte: $5</p>
                    <p>Hot Chocolate: $5</p>
                    <p>Bushwick Tea: $4</p>
            </div>
        </div>
    )
}

export default Inbetweener;