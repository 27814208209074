function Dinner() {
    return (
        <div id="dinner">
             <div className="mobile-address-topper">
                <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                718-383-5345 MON-SUN 8A-11P</p>
            </div>
            <h1 className ="FM-h1">Dinner</h1>
            <div className="row1">
                <img src={require('../../menu_images/DINNER_1.png')} alt='seasonal dinner menu pg 1'></img>
                <img src={require('../../menu_images/DINNER_2.png')} alt='seasonal dinner menu pg 2'></img>
            </div>
            <div className="row2">
                <img src={require('../../menu_images/DINNER_3.png')} alt='seasonal dinner menu pg 3'></img>
                <img src={require('../../menu_images/DINNER_4.png')} alt='seasonal dinner menu pg 4'></img>
            </div>
            <div className="screen-reader">
                <h3>Starters</h3>
                    <p>Daily Market Oysters. Half Dozen: $18. Dozen: $36</p>
                    <p>Daily Soup: $12</p>
                    {/* <p>Hiramasa Crudo: $20</p> */}
                    <p>Deviled Eggs: $8</p>
                    <p>Ricotta Kimchee Dumplings: $15</p>
                    <p>Cheese Bread: $10</p>
                    <p>Roasted Caulifower: $15</p>
                    {/* <p>Waffles & Roe: $18</p> */}
                    <p>Devils On Horseback: $12</p>
                    <p>Green Pea Falafel: $18</p>
                    {/* <p>Skewers (two skewers): $22</p> */}
                    {/* <p>add extra skewer: $9</p> */}
                    <p>Housemade Ricotta: $18</p>
                    <p>French Onion Soup: $15</p>
                    <p>Artisinal Cheese Board: $26</p>
                    <p>Fried Olives: $10</p>
                    <p>Blue Cheese Dip: $15</p>
                <h3>Mains</h3>
                    <p>Pan Seared Salmon: $33</p>
                    <p>Roasted Chicken: $33</p>
                    <p>Braised Beef Pappardelle: $28</p>
                    <p>Steamed Mussels: $28</p>
                    <p>Whole Branzino: $35</p>
                    <p>Lamb Shepperd's Pie: $26</p>
                    <p>Veggie Burger: $21</p>
                    <p>Five Leaves Burger: $24</p>
                    <p>Burger: $21</p>
                    <p>Burger Additions : $2 each : bacon, chedder, gruyerre, blue, truffle fries</p>
                    <p>Grilled Hangar Steak: $36</p>
                    <p>Choose Two Sides: RoastedHeirloom Carrots, Crispy Brussel Sprouts, Fries, Truffle Fries, Blistered Shishitos, Celery Root + Potato Mash</p>
                <h4>Salad</h4>
                    <p>Market Greens: $12</p>
                    <p>Black Kale: $16</p>
                    <p>Little Gem Wedge: $18</p>
                    <p>Add... Grilled Halloumi ($9), Marinated Chicken ($10), Grilled Shrimp ($12), Seared Salmon ($15), Grilled Steak ($15)</p>
                <h3>Sides</h3>
                    <p>Roasted Heirloom Carrots: $12</p>
                    <p>Crispy Brussel Sprouts: $10</p>
                    <p>Blistered Shishitos: $12</p>
                    <p>Celery Root + Potato Mash: $9</p>
                    <p>Fries: $7</p>
                    <p>Truffle Fries: $9</p>
                <h3>Dessert</h3>
                    <p>Sticky Date Pudding: $12</p>
                    <p>Rosewater Pavlova: $12</p>
                    <p>Wine Poached Apple Strudel: $12</p>
                    <p>Affogato: $8</p>
                <h3>Coffee & Tea</h3>
                    <p>Espresso: $5</p>
                    <p>Americano: $5</p>
                    <p>Latte: $6</p>
                    <p>Flat White: $6</p>
                    <p>Cappuccino: $6</p>
                    <p>Cold Brew: $5</p>
                    <p>Mocha: $6</p>
                    <p>Turmeric Latte: $5</p>
                    <p>Hot Chocolate: $5</p>
                    <p>Bushwick Tea: $4</p>
            </div>
        </div>
    )
}

export default Dinner;