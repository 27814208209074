import React, { useEffect, useState } from 'react';


function Contact() {
    return (
        
        <div id='contact-content-wrapper'>
             <div className="mobile-address-topper">
                <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                718-383-5345 MON-SUN 8A-11P</p>
            </div>
            <div id='contact-content'>
                <div id='contact-bottom'>
                    <div className='contact-item'>
                        <div className='contact-item-header'>GENERAL ENQUIRIES</div>
                        <div className='contact-item-info'><a href='mailto:info@fiveleavesny.com' alt='send us an email'>info@fiveleavesny.com</a></div>
                        <div className="food-menu-title-ornament-container">
                            <div className="food-menu-title-ornament"></div>
                        </div>
                    </div>
                    <div id='contact-top'>
                        <div id='contact-top-image'>
                            <img src={require('../images/fish-small.gif')} alt='dapper fish smoking a pipe'/>
                        </div>
                    </div>
                    <div className='contact-item contact-item-right'>
                        <div className='contact-item-header'>PRESS ENQUIRIES</div>
                        <div className='contact-item-info'><a href='mailto:press@fiveleavesny.com' alt='email for press'>press@fiveleavesny.com</a></div>
                        <div className="food-menu-title-ornament-container">
                            <div className="food-menu-title-ornament"></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Contact;