import React, { useState, useEffect } from 'react';
// import { NavLink, Redirect, Link, useHistory } from 'react-router-dom';

function Footer() {
    const [showFoodMenus, setShowFoodMenus] = useState(false);
    const [showingSideBar, setShowingSideBar] = useState(false);
    
    // const showSideBar = () => {
    //     let mmButton = document.getElementById("mobile-menu-buttons-inner");
    //     let contentBlocker = document.getElementById("content-blocker");
        // if (mmButton.style.marginLeft === "0px") {
    //     if (!showingSideBar) {
    //         mmButton.style.marginLeft = "-150px";
    //         mmButton.style.transition = ".4s ease-out";
    //         contentBlocker.style.display = "none";
    //         setShowingSideBar(true);
    //         console.log(showingSideBar)
    //         return;
    //     } else {
    //         mmButton.style.marginLeft = "0px";
    //         contentBlocker.style.display = "block";
    //         contentBlocker.style.opacity = "0.7";
    //         setShowingSideBar(false);
    //         console.log(showingSideBar)
    //         return;
    //     }
    // }
    const openSideBar = () => {
        if (showingSideBar) return;
        setShowingSideBar(true);
    }

    const closeSideBar = () => {
        setShowingSideBar(false);
    }

    const openFoodMenus = () => {
        if (showFoodMenus) {
            setShowFoodMenus(false);
        } else {
            setShowFoodMenus(true);
        }
    }

    useEffect(() => {
        if (!showingSideBar) return;

        window.addEventListener('click', function(e) {
            if (document.getElementById("mobile-menu-button-lines") === null) return;
            if (document.getElementById("mobile-menu-buttons-inner") === null) return;
            if (!document.getElementById("mobile-menu-buttons-inner").contains(e.target) && !document.getElementById("mobile-menu-button-lines").contains(e.target) && !document.getElementById("mobile-menu-header").contains(e.target)) closeSideBar();
        })

        return () => window.removeEventListener('click', closeSideBar);
   
    }, [showingSideBar])

    return (
        <div id="footer">
            <div id="footer-border-top">
                <div className="menu-border-bottom-bottom"></div>
                <div className="menu-border-bottom-top"></div>
            </div>
            <div id="footer-content">
                <div id="social-links">
                    <a className="footer-social-link" id="footer-facebook-logo" href="https://www.facebook.com/pages/Five-Leaves/52023373198" target="_blank" aria-label="visit our facebook page">
                        <i className="fa-brands fa-square-facebook" alt="facebook"></i>
                    </a>
                    <a className="footer-social-link" id="footer-instagram-logo" href="http://instagram.com/fiveleavesny" target="_blank" aria-label="visit our instagram">
                        <i className="fa-brands fa-square-instagram" alt="instagram"></i>
                    </a>
                </div>
            </div>
        
            <div id="content-blocker" style={{display: showingSideBar ? "block" : "none", opacity: "0.7"}}></div>
            <div id="preloader-container"></div> 
            <div id="mobile-menu-wrapper">
                <div id="mobile-menu-header">
                    <div id="mobile-menu-button">
                        <button id="mobile-menu-button-lines" onClick={showingSideBar ? closeSideBar : openSideBar}>
                            <div className="mobile-menu-button-line"></div>
                            <div className="mobile-menu-button-line"></div>
                            <div className="mobile-menu-button-line"></div>
                        </button>
                    </div>
                    <div id="mobile-menu-logo"> 
                    <p id="FLM-logo">FIVE LEAVES</p>
                        <div id="mobile-menu-buttons">
                            <ul id="mobile-menu-buttons-inner" style={{marginLeft: showingSideBar ? "0px" : "-150px", transition: ".4s ease-out"}}>
                                
                                    <li className="mobile-menu-button"><a className="mobile-menu-a" href="/">Home</a>
                                    </li>
                                
                                    <li className="mobile-menu-button" id="menus-downdrop" onClick={openFoodMenus}><a className="mobile-menu-a">Menus</a>
                                    </li>
                                    
                                    {showFoodMenus && (
                                    <div id="mobile-side-food-menu-buttons-inner">
                                        <li className="mobile-menu-button-food"><a className="mobile-menu-a" href="brunch">- Brunch</a>
                                        </li>

                                        <li className="mobile-menu-button-food"><a className="mobile-menu-a" href="inbetweener">- Inbetweener</a>
                                        </li>

                                        <li className="mobile-menu-button-food"><a className="mobile-menu-a" href="dinner">- Dinner</a>
                                        </li>

                                        <li className="mobile-menu-button-food"><a className="mobile-menu-a" href="cocktail">- Cocktails</a>
                                        </li>

                                        <li className="mobile-menu-button-food"><a className="mobile-menu-a" href="wine">- Wine</a>
                                        </li>
                                    </div>
                                    )}
                                
                                    <li className="mobile-menu-button"><a className="mobile-menu-a" href="https://resy.com/cities/ny/4966?gclid=Cj0KCQiAys2MBhDOARIsAFf1D1eRu3HxiFrXVTAjImbEJ0etwMIQ9z0I06FwfdYAq4kpuGKdI6EeDGcaAt5fEALw_wcB&amp;gclsrc=aw.ds&amp;date=2021-11-17&amp;seats=2" target="_blank">Reservations</a>
                                    </li>
                                
                                    <li className="mobile-menu-button"><a className="mobile-menu-a" href="https://www.fiveleavesdiningclub.com/" target="_blank">Shop</a>
                                    </li>
                                
                                    <li className="mobile-menu-button"><a className="mobile-menu-a" href="https://order.online/business/five-leaves-15135" target="_blank">Order Online</a>
                                    </li>
                                
                                    <li className="mobile-menu-button"><a className="mobile-menu-a" href="location">Location</a>
                                    </li>
                                
                                    <li className="mobile-menu-button"><a className="mobile-menu-a" href="contact">Contact</a>
                                    </li>
                                
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;