import React, { useEffect, useState } from 'react';


function Location() {
    return (
            <div id="map-wrapper">
                 <div className="mobile-address-topper">
                    <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                    718-383-5345 MON-SUN 8A-11P</p>
                </div>
                <div id="map-container">
                    
                    <a className="map-link" href="https://www.google.com/maps/place/Five+Leaves/@40.7237372,-73.9516351,15z/data=!4m5!3m4!1s0x0:0xe68f335e68bc5bee!8m2!3d40.7237372!4d-73.9516351?hl=en" target="_blank" alt="find us on google maps">
                        <img className="map-image" src={require("../images/contact-map.jpg")} alt="five leaves map"/>
                    </a>
                </div>
            </div>
    )
}

export default Location;