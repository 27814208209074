import React from 'react';
// import { NavLink, Redirect, Link, useHistory } from 'react-router-dom';
import '../App.css';
import MenusDropdown from './MenusDropdown';

function Navigation() {
    return (
        <div id="wrapper">
            <div id="content">
                <header>
                    <div id="logo-container">
                        <div id="logo-left-text">
                            18 BEDFORD AVE, GREENPOINT, BK, 11222
                        </div>
                        <a id="logo" href="/" alt="click to go back to the homepage">
                            <img src={require('../images/logo.gif')} alt="five leaves logo"/>
                            <img id="logo-highlight" src={require('../images/logo-highlight.png')} alt="logo border"/>
                        </a>
                        <div id="logo-right-text">
                            718-383-5345 • MON-SUN 8A-11P
                        </div>
                    </div>
                    <div id="menu-border-top"></div>
                    <div id="main-menu-container">
                        <ul id="left-menu">       
                                <li className="main-menu-button"><a className="main-menu-a" href="location" alt="click to go to the location page">Location</a></li>
                                <li className="main-menu-button"><a className="main-menu-a" href="https://resy.com/cities/ny/4966?gclid=Cj0KCQiAys2MBhDOARIsAFf1D1eRu3HxiFrXVTAjImbEJ0etwMIQ9z0I06FwfdYAq4kpuGKdI6EeDGcaAt5fEALw_wcB&amp;gclsrc=aw.ds&amp;date=2021-11-17&amp;seats=2" target="_blank" alt="click to make a reservation">Reservations</a></li>      
                                {/* <li className="main-menu-button"><MenusDropdown/></li> */}
                                <MenusDropdown/>
                        </ul>
                        <ul id="right-menu">
                                <li className="main-menu-button"><a className="main-menu-a" href="https://www.fiveleavesdiningclub.com/" target="_blank" alt="click to visit our shop">Shop</a></li>
                                <li className="main-menu-button"><a className="main-menu-a" href="https://order.online/business/five-leaves-15135" target="_blank" alt="click to order online">Order Online</a></li>
                                <li className="main-menu-button"><a className="main-menu-a" href="contact" alt="click to go to the contact page">Contact</a></li>
                        </ul>
                    </div>
                    <div id="menu-border-bottom">
                        <div className="menu-border-bottom-top"></div>
                        <div className="menu-border-bottom-bottom"></div>
                    </div>
                </header>
            </div>
        </div>
    )
}

export default Navigation;