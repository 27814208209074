import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HomePage from './components/HomePage';
import Location from './components/Location';
import Contact from './components/Contact';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Brunch from './components/Menus/Brunch';
import Dinner from './components/Menus/Dinner';
import Cocktail from './components/Menus/Cocktail';
import Inbetweener from './components/Menus/Inbetweener';
import Wine from './components/Menus/Wine';

function App() {
  return (
    <>
      <Navigation />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/location">
            <Location />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/brunch">
            <Brunch />
          </Route>
          <Route path="/dinner">
            <Dinner />
          </Route>
          <Route path="/cocktail">
            <Cocktail />
          </Route>
          <Route path="/inbetweener">
            <Inbetweener />
          </Route>
          <Route path="/wine">
            <Wine />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      <Footer />
    </>
  );
}

export default App;
